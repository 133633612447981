<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" :numberrows="numberrows" />
      <Steps
        :levels="levels"
        :hierarchy="hierarchy"
        v-if="levels && appStatus == 1"
      />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="基本信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="采购预案名称" :value="details.projtopic" />
                    <van-cell title="编制日期" :value="details.createdate" />
                    <van-cell title="需求类型" :value="details.reqName" />
                    <van-cell title="项目名称" :value="details.projname" />
                    <van-cell
                      title="联合采购"
                      :value="details.jointprocurement == 1 ? '是' : '否'"
                      v-if="details.frmtype == 1"
                    />
                    <van-cell
                      title="采购负责单位"
                      :value="details.responsibleunitName"
                      v-if="
                        details.frmtype == 1 && details.jointprocurement == 1
                      "
                    />
                    <van-cell
                      title="签约方式"
                      :value="details.directionName"
                      v-if="details.frmtype == 1"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="主要信息"
                  name="2"
                  icon="label"
                  v-if="details.frmtype == 0"
                >
                  <van-cell-group class="group">
                    <van-cell title="采购方式" :value="details.stockName" />
                    <van-cell
                      title="已选框架协议"
                      :value="details.agreementName"
                      v-if="details.stocktype == '10002550'"
                    />
                    <van-cell
                      title="发送订货函"
                      :value="details.ifpricefeedback == 1 ? '是' : '否'"
                      v-if="details.stocktype == '10002550'"
                    />
                    <van-cell
                      title="询价方式"
                      v-if="
                        details.stocktype == '10002400' ||
                          details.stocktype == '10041090'
                      "
                    >
                      <template #default>
                        <span>{{
                          details.projectrange === 0 ? "公开" : "邀请"
                        }}</span>
                      </template>
                    </van-cell>
                    <van-cell
                      title="紧急采购"
                      :value="details.urgentpurchase == 1 ? '是' : '否'"
                      v-if="
                        details.stocktype == '10002400' ||
                          details.stocktype == '10002500' ||
                          details.stocktype == '10041090' ||
                          details.stocktype == '10002550'
                      "
                    />
                    <van-cell
                      title="紧急采购时间"
                      :value="details.urgentpurchasetime"
                      v-if="details.urgentpurchase == 1"
                    />
                    <van-cell
                      title="紧急采购原因"
                      :value="details.urgentpurchaseremark"
                      v-if="details.urgentpurchase == 1"
                    />
                    <van-cell
                      title="资格审查方式"
                      :value="details.qualificationName"
                      v-if="details.stocktype == '10002490'"
                    />
                    <van-cell
                      title="资金来源"
                      :value="details.moneysourceName"
                      v-if="
                        details.stocktype == '10002490' ||
                          details.stocktype == '10002430'
                      "
                    />
                    <van-cell
                      title="技术交流信息"
                      :value="details.isTechnicalExchange == 1 ? '有' : '无'"
                      v-if="details.stocktype != '10002490'"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="主要信息"
                  name="2"
                  icon="label"
                  v-if="details.frmtype == '1'"
                >
                  <van-cell-group class="group">
                    <van-cell title="采购方式" :value="details.stockName" />
                    <van-cell
                      title="询价方式"
                      v-if="
                        details.stocktype == '10041070' ||
                          details.stocktype == '10002400' ||
                          details.stocktype == '10041090'
                      "
                    >
                      <template #default>
                        <span>{{
                          details.projectrange === 0 ? "公开" : "邀请"
                        }}</span>
                      </template>
                    </van-cell>
                    <van-cell
                      title="分包方式"
                      v-if="
                        details.stocktype == '10041100' ||
                          details.stocktype == '10002490'
                      "
                    >
                      <template #default>
                        <span>{{
                          details.packway == 1 ? "按企业分包" : "按物资分包"
                        }}</span>
                      </template>
                    </van-cell>
                    <van-cell
                      title="资格审查方式"
                      :value="details.qualificationName"
                      v-if="
                        details.stocktype == '10041100' ||
                          details.stocktype == '10002490'
                      "
                    />
                    <van-cell
                      title="资金来源"
                      :value="details.moneysourceName"
                      v-if="
                        details.stocktype == '10041100' ||
                          details.stocktype == '10002490' ||
                          details.stocktype == '10002430'
                      "
                    />
                    <van-cell
                      title="框架协议类型"
                      :value="details.agreementTypeName"
                    />
                    <van-cell
                      title="定价机制"
                      :value="details.pricingtypeName"
                    />
                    <van-cell
                      title="供应份额分配原则"
                      :value="details.suppasign"
                    />
                    <van-cell
                      v-if="details.pricingtypeName == '计价公式'"
                      title="计价公式备注"
                      :value="details.pricinginfo"
                    />
                    <van-cell
                      v-if="details.pricingtypeName == '调价机制'"
                      title="调价机制备注"
                      :value="details.pricinginfo"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="技术交流信息"
                  name="3"
                  icon="label"
                  v-if="
                    details.stocktype != '10002490' &&
                      details.frmtype == 0 &&
                      details.isTechnicalExchange == 1
                  "
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="技术交流开始日期"
                      :value="
                        details.technicalExchangeVO.technicalExchangeStartdate
                      "
                    />
                    <van-cell
                      title="技术交流结束日期"
                      :value="
                        details.technicalExchangeVO.technicalExchangeEnddate
                      "
                    />
                    <van-cell
                      title="主持方"
                      :value="
                        details.technicalExchangeVO.technicalExchangePresider
                      "
                    />
                    <van-cell
                      title="参加方"
                      :value="
                        details.technicalExchangeVO.technicalExchangeJoiner
                      "
                    />
                    <van-cell title="附件">
                      <div
                        v-for="(item, index) in details.technicalExchangeVO
                          .files"
                        :key="index"
                        class="cell"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/file/FileuploadAction/' +
                              item.id +
                              '-' +
                              item.title
                          "
                          >{{ item.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="一般信息" name="3" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="采购类型" :value="details.attrName" />
                    <van-cell
                      title="合同类型"
                      :value="details.contractTypeName"
                    />
                    <van-cell
                      title="国内/进口"
                      :value="details.importflag === 0 ? '国内' : '进口'"
                    />
                    <van-cell
                      title="项目批准文号"
                      :value="details.projpassno"
                    />
                    <van-cell
                      title="概算币种"
                      :value="details.estimatecurrencyName"
                    />
                    <van-cell title="概算金额(元)" :value="details.rmbamount" />
                    <van-cell title="税率" :value="details.taxName" />
                    <van-cell
                      title="人民币概算金额(元)"
                      :value="details.rmbamount"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="集中会审信息" name="4" icon="label">
                  <van-cell-group class="group">
                    <van-cell
                      title="是否集中会审"
                      :value="details.isConsultation === 1 ? '是' : '否'"
                    />
                    <van-cell
                      title="集中会审文件"
                      value=""
                      v-if="details.isConsultation === 1"
                    >
                      <div
                        v-for="(item, index) in details.consultationTrialFiles"
                        :key="index"
                        class="cell"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/file/FileuploadAction/' +
                              item.id +
                              '-' +
                              item.title
                          "
                          >{{ item.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="备注与附件" name="5" icon="label">
                  <van-cell-group class="group">
                    <van-cell
                      title="独家采购原因"
                      :value="details.onlypublicitybillVOS[0].onlyReasonName"
                      v-if="details.ifonly == 1"
                    />
                    <van-cell
                      title="独家免公示"
                      :value="
                        details.onlypublicitybillVOS[0].publicitytype == 3
                          ? '是'
                          : '否'
                      "
                      v-if="details.ifonly == 1"
                    />
                    <van-cell title="备注" :value="details.idea" />
                    <van-cell title="附件">
                      <div
                        v-for="(item, index) in details.files"
                        :key="index"
                        class="cell"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/file/FileuploadAction/' +
                              item.id +
                              '-' +
                              item.title
                          "
                          >{{ item.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="经办人信息" name="6" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="提报单位" :value="details.compName" />
                    <van-cell title="提报人" :value="details.submitUserName" />
                    <van-cell
                      title="提报人联系方式"
                      :value="details.submitUserTel"
                    />
                    <van-cell title="提报日期" :value="details.subtime" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="独家采购公示监督人信息"
                  name="6"
                  icon="label"
                  v-if="details.ifonly == 1"
                >
                  <van-cell-group
                    class="group"
                    v-for="(item, index) in details.onlypublicitybillVOS"
                    :key="index"
                  >
                    <van-cell
                      title="监督联系人"
                      :value="item.supervisecontact"
                    />
                    <van-cell title="电话" :value="item.supervisetel" />
                    <van-cell title="电子邮箱" :value="item.superviseemail" />
                    <van-cell title="传真" :value="item.supervisefax" />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="物资信息" name="b">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              :immediate-check="false"
            >
              <div
                class="supplies"
                v-for="(item, index) in projectlist"
                :key="index"
              >
                <div class="supplies-title">{{ item.strprodname }}</div>
                <div class="supplies-text">物资编码:{{ item.strprodcode }}</div>
                <div class="supplies-text">
                  采购申请编号: {{ item.ordercode }}
                </div>
                <div class="supplies-text">
                  数量: {{ item.number }}{{ item.measunit }}
                </div>
                <div class="supplies-text" v-if="details.frmtype != 1">
                  计划交货日期:{{ item.startdate }}
                </div>
                <div class="supplies-text">提交日期: {{ item.subtime }}</div>
              </div>
            </van-list>
          </van-tab>
          <van-tab title="供应商" name="c"
            ><van-list :finished="true" finished-text="没有更多了">
              <div
                class="supplies"
                v-for="(item, index) in details.projectsuppS"
                :key="index"
              >
                <div class="supplies-title">{{ item.suppliername }}</div>
                <div class="supplies-text">可供条数: {{ item.kgsl }}</div>
                <div class="supplies-text">
                  星级:
                  <van-icon
                    name="star-o"
                    v-for="item in item.star"
                    :key="item"
                  />
                </div>
                <div class="supplies-text">法人信用认证: {{ item.jzlpf }}</div>
                <div class="supplies-text">产品质量评价: {{ item.xypf }}</div>
                <div class="supplies-text">有无交易: {{ item.risk }}</div>
                <div class="supplies-text">
                  违约记录:
                  {{
                    item.sxxw == 1
                      ? "失信"
                      : item.sxxw == 2
                      ? "以往有失信行为"
                      : item.sxxw == 3
                      ? "未失信"
                      : ""
                  }}
                </div>
                <div class="supplies-text">
                  服务关系: {{ item.supptype === 1 ? "有" : "无" }}
                </div>
              </div>
            </van-list></van-tab
          >
          <van-tab title="审批记录" name="c" v-if="appStatus != 1">
            <div
              class="record"
              v-for="(item, index) in details.wfapproveinfos"
              :key="index"
            >
              <van-cell-group>
                <van-cell title="审批层级" :value="item.node"></van-cell>
                <van-cell title="审批节点" :value="item.nodename"></van-cell>
                <van-cell title="审批人" :value="item.userName"></van-cell>
                <van-cell title="审批时间" :value="item.enddatetime"></van-cell>
                <van-cell title="审批结论" :value="item.resultType"></van-cell>
                <van-cell
                  title="审批意见"
                  :value="item.result"
                  v-if="item.resultType == '不同意'"
                ></van-cell>
              </van-cell-group>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      levels: "",
      title: "采购预案审批详情",
      showage: true,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      numberrows: "2",
      projectlist: [],
      hierarchy: "",
      basicTop: {
        data: [],
        columns: [
          {
            prop: "stockName",
            label: "采购方式",
          },
          {
            prop: "rmbamount",
            label: "概算金额(元)",
          },
          {
            prop: "projname",
            label: "项目名称",
          },
          {
            prop: "contractTypeName",
            label: "合同类型",
          },
        ],
      },
    };
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  created() {
    this.getProjectView();
    this.Projectlist();
  },
  methods: {
    async getProjectView() {
      let data = await this.$api.ec.getProjectView(this.stockaffirmId);
      if (data) {
        this.loadingfalg = false;
      }
      if (data.frmtype == 1) {
        this.numberrows = 3;
        this.basicTop.columns = [
          {
            prop: "stockName",
            label: "采购方式",
          },
          {
            prop: "rmbamount",
            label: "概算金额(元)",
          },
          {
            prop: "contractTypeName",
            label: "合同类型",
          },
          {
            prop: "projname",
            label: "项目名称",
          },
          {
            prop: "directionName",
            label: "签约方式",
          },
        ];
      }
      this.basicTop.data = data;
      data.wfnodeVOs.map((item, index) => {
        if (item.isNodeaction == 1) {
          this.levels = index + 1;
          return;
        }
      });
      this.hierarchy = data.wfnodeVOs.length;
      this.details = data;
      if (this.details.files) {
        this.details.files.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      if (this.details.technicalExchangeVO) {
        if (this.details.technicalExchangeVO.files) {
          this.details.technicalExchangeVO.files.map((item) => {
            item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
          });
        }
        // if (this.details.technicalExchangeVO) {
        //   this.details.technicalExchangeVO.map((item) => {
        //     item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        //   });
        // }
        if (this.details.consultationTrialFiles) {
          this.details.consultationTrialFiles.map((item) => {
            item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
          });
        }
      }

      console.log(this.details.files);
    },
    async Projectlist() {
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        projectId: this.stockaffirmId,
      };
      let data = await this.$api.ec.Projectlist(params);
      if (data) {
        if (data.data) {
          if (data.data.length != 0) {
            this.projectlist = [...this.projectlist, ...data.data];
          }
          if (data.data.length != 10) {
            this.loading = true;
            this.finished = true;
          } else {
            this.loading = false;
            this.finished = false;
          }
        }
        if (!data.data) {
          this.loading = true;
          this.finished = true;
        }
      }
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "ecprocurement",
        });
      } else {
        this.showage = true;
        this.title = "采购预案审批详情";
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onLoad() {
      this.pageIndex++;
      this.Projectlist();
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          proiectId: this.stockaffirmId,
          audit: 0,
          leadexam: message,
        };
      } else {
        params = {
          proiectId: this.stockaffirmId,
          audit: 1,
          leadexam: "同意",
        };
      }
      let data = await this.$api.ec.auditProject(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecprocurement",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {});
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.record {
  margin-top: 10px;
}
</style>
